import { usePassThrough } from 'primereact/passthrough';
import Tailwind from 'primereact/passthrough/tailwind';

export default function usePrPassthrough() {
	return usePassThrough(
		Tailwind,
		{
			button: {
				label: {
					className: 'font-medium',
				},
				root: {
					className: 'focus:shadow-none',
				},
			},
			dialog: {
				header: { className: '!bg-gardian-darkblue text-white' },
				content: {
					className:
						'!bg-gardian-lightgray pt-8 flex flex-col gap-6 max-h-[calc(100vh-80px)]',
				},
				footer: {
					className: '!bg-gardian-lightgray !px-4 !pb-2',
				},
				closeButton: {
					className:
						'text-white hover:bg-transparent hover:text-white',
				},
			},
			fieldset: {
				legend: {
					className: 'focus:shadow-none',
				},
			},
			datatable: {
				paginator: {
					RPPDropdown: {
						root: {
							className: '!w-20',
						},
					},
				},
				header: {
					className: '!p-0',
				},
			},
			inputtext: {
				root: {
					className: 'font-medium focus:shadow-none',
				},
			},
			tabmenu: {
				root: {
					className: 'p-0 overflow-hidden',
				},
				action: {
					className:
						'!p-2 !top-0  !rounded-none !shadow-none !bg-transparent',
				},
				inkbar: {
					className: 'hidden',
				},
				label: {
					className: 'font-normal',
				},
				menu: {
					className: '!bg-transparent',
				},
			},
		},
		{
			mergeSections: true,
			mergeProps: true,
		}
	);
}
