import { Button } from 'primereact/button';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { PrimeReactProvider } from 'primereact/api';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '../lib/react-query';
import usePrPassthrough from '../hooks/usePrPassthrough';
import { Loader } from '../components/Loader';
import { AuthProvider } from './auth';
import { DownloadProvider } from './download';
import { TokenProvider } from './token';

type ErrorFallbackProps = {
	error: Error;
};

export const ErrorFallback = ({ error }: ErrorFallbackProps) => {
	return (
		<div
			className="flex h-screen w-screen flex-col items-center justify-center text-red-500"
			role="alert"
		>
			<h2 className="text-lg font-semibold">
				Ooops, something went wrong :({' '}
			</h2>
			<h3>{error.message}</h3>
			<Button
				className="mt-4"
				onClick={() => window.location.assign(window.location.origin)}
			>
				Refresh
			</Button>
		</div>
	);
};

type AppProviderProps = {
	children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
	const CustomTailwind = usePrPassthrough();
	return (
		<React.Suspense fallback={<Loader />}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<AuthProvider>
					<QueryClientProvider client={queryClient}>
						<PrimeReactProvider
							value={{ unstyled: true, pt: CustomTailwind }}
						>
							<DownloadProvider>
								<TokenProvider>
									<HelmetProvider>{children}</HelmetProvider>
								</TokenProvider>
							</DownloadProvider>
						</PrimeReactProvider>
					</QueryClientProvider>
				</AuthProvider>
			</ErrorBoundary>
		</React.Suspense>
	);
};
