import { createContext, useState } from 'react';

export type DownloadContextType = {
	pending: boolean;
	setPending: React.Dispatch<boolean>;
};

export const DownloadContext = createContext<DownloadContextType>({
	pending: false,
	setPending: () => {},
});

type DownloadProviderProps = {
	children: React.ReactNode;
};

export const DownloadProvider = ({ children }: DownloadProviderProps) => {
	const [pending, setPending] = useState<boolean>(false);

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<DownloadContext.Provider value={{ pending, setPending }}>
			{children}
		</DownloadContext.Provider>
	);
};
