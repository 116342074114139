import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';

type AuthProviderProps = {
	children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
	return (
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
			authorizationParams={{
				redirect_uri: process.env.REACT_APP_REDIRECT_URL,
				audience: process.env.REACT_APP_AUTH0_API_ID,
				scope: process.env.REACT_APP_AUTH0_SCOPE,
				responseType: 'token id_token',
			}}
			cacheLocation={'localstorage'}
		>
			{children}
		</Auth0Provider>
	);
};
